
import React from "react"

function VLine(){
    return (
        <svg width="7" height="156" viewBox="0 0 7 156" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.4319 154.313c-2.4348-1.8966-.2669 9.6598-.8186-17.2292.7221-5.616-.0354-8.9675 1.3123-14.6639.2371-5.3707-1.0965-5.659-.8638-11.0311.4154-4.975-.4182-14.5791.8175-16.5105-.8487-8.2327-1.6923-25.6061-.4474-34.89-1.8093-2.601.1715-6.7948-.8682-11.035l.2883-16.516c-.0772-9.6008-2.254-21.4726-.5158-31.2218 1.031-2.84 3.0932 1.4443 2.4877 9.2241.7665 3.8467.1532 12.1928.7677 16.5344 1.1484 12.0388.0643 22.5984 1.013 33.0696-.2825 10.8736.278 22.8559.4473 34.8901.1562 11.0113.2717 22.337-.0774 34.8822-.6895 5.2666.0774 7.268-.37 15.8386L2.432 154.313z" fill="currentColor" fillRule="nonzero"/>
        </svg>
    );
}

export default VLine