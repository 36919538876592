
import React from "react"
import "./activity.css"
import { Link } from "gatsby"
import Button from "../components/button"

function Activity(props){
    return (
        <div className={"vp-section-header columns aic mb2 vp-activity-card" + (props.even ? "--even" : "")}>
          <div className="column column-50">
            <h3 className="">{props.title}</h3>
            <p>{props.subtitle}</p>
            <Link to={props.link}>
                <Button color={props.color}>{props.btnText}</Button>
            </Link>
          </div>
          <div className="column column-50">
            <img src={props.image} className="" alt={props.title}></img>
          </div>
        </div>
    );
}

export default Activity