
import React from "react"

function Palette(){
    return (
        <svg width="99" height="102" viewBox="0 0 99 102" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <path id="a" d="M.06.142h20.7071288v63.865481H.06z"/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path d="M77.984 37.87c4.776 5.581 18.286.685 20.42 9.67-.014 12.264-6.919 22.661-13.431 30.079-6.356 7.244-19.13 17.168-28.488 20.951-2.987 1.208-7.16 1.932-11.485 2.318v-2.419c11.322-.999 18.748-6.076 26.535-12.253 5.187-4.116 10.854-8.437 14.51-13.429 4.831-6.599 10.109-15.416 9.14-25.247-6.998-3.331-18.323-.812-19.89-8.595-.897-4.462 2.571-7.586 3.763-12.892 1.688-7.506.986-14.057-2.686-19.876-4.396-1.878-11.292-4.192-15.588-2.688C56.533 4.978 50.925 8.187 45 11.15V6.916C51.807 3.439 59.781.057 68.308.267c7.913.194 15.729 3.739 16.126 14.503.301 8.238-5.367 15.335-6.45 23.1" fill="currentColor"/>
                <path d="M48.96 34.645c-.038.008.034.5 0 .538-2.553 2.915 2.58-.993 0-.538zm-1.076 7.522c6.126 5.887 18.027 1.1 13.973-9.133-6.452 1.249-11.753 3.651-13.973 9.133zm6.45-10.742v1.073c1.323.835 1.58-1.824.536-1.611-.029.326-.079.635-.536.538zm8.064-4.837c4.252 6.898 2.861 19.032-5.377 20.414-4.161.697-8.412-1.314-12.021-3.547v-7.156c3.71-5.28 9.816-10.094 17.398-9.711zM73.148 72.25c3.244 2.305 8.985-2.613 6.448-4.298-1.27 2.31-4.546 2.616-6.448 4.298zm2.147-12.895c4.749-.984 7.116 2.299 7.526 5.375 1.325 9.917-13.254 13.866-13.976 3.759-.24-3.373 3.211-8.461 6.45-9.134z" fill="currentColor"/>
                <g transform="translate(45 21)">
                <path d="M12.021 62.529c2.968-.615 5.44-1.727 5.914-4.835-2.611.972-3.839 3.328-5.914 4.835zm-1.611-5.371c.129-.765.884-.904 1.075-1.611-1.107-.471-2.415 1.393-1.075 1.611zm7.525-4.298c-.433 3.253 2.463.359 0 0zm-.537-1.61c-.717.08-1.624-2.185-2.152-.538.34.298 1.624 2.182 2.152.538zm3.222.537c2.06 14.235-18.21 16.514-17.197 4.296.579-6.981 12.402-11.642 17.197-4.296zM0 .142c.841 1.033.73 2.473 0 4.003V.142" fill="currentColor"/>
                </g>
                <g fill="currentColor">
                <path d="M45 100.888c-2.643.238-5.344.348-7.862.37-10.806.085-16.114-2.912-22.576-9.134C6.71 84.564-.562 79.187.586 63.653 1.62 49.684 11.365 31.385 18.862 23.365c4.891-5.235 12.728-9.33 20.424-13.43 1.793-.953 3.702-1.99 5.714-3.019v4.234c-1 .501-2.012.993-3.027 1.471-6.558 3.082-13.311 6.466-17.738 10.207C14.979 30.653 5.44 45.663 3.812 59.355c-1.153 9.707 1.209 13.967 4.299 19.339 1.549 2.694 2.476 5.77 4.3 8.06 6.447 8.084 16.991 12.618 31.172 11.816.48-.026.952-.062 1.417-.101v2.419"/>
                <path d="M45 43.455l-.342-.213c.77-2.369-.244-3.367 0-6.446.114-.165.227-.331.342-.497v7.156M21.548 51.836c2.169-.16 3.868-.791 3.762-3.223-1.975.353-2.671 1.981-3.762 3.223zm3.762-7.52c1.569.788 1.569-2.402 0-1.612v1.612zm.538 8.057c-9.579 6.729-16.261-6.842-8.597-13.428 8.203-7.058 15.148 6.51 8.597 13.428zM36.061 86.216c-1.732.197-3.866-1.274-5.373-.537 1.351.514 4.667 2.683 5.373.537zm-1.075-2.148c1.107.471 2.411-1.394 1.075-1.613-.048.846-1.371.419-1.075 1.613zm-4.839-.539c.033-.327.083-.637.541-.535-.064-.655-.165-1.273-1.081-1.075.089.631-.088 1.524.54 1.61zm2.688-1.61c1.554-.239 2.45-1.132 2.689-2.685-1.556.237-2.451 1.131-2.689 2.685zm5.914 4.297c1.25-2.071 4.334-7.443 1.072-9.133-.643 3.402-.81 5.878-1.072 9.133zm-8.061-6.445c1.347 1.084 4.762-2.556 3.762-3.762-1.648.861-3.208 1.81-3.762 3.762zm.536-7.521c14.682-5.155 14.608 14.525 5.914 16.651-3.369.824-9.32-.647-10.751-4.297-1.788-4.545 2.014-9.488 4.837-12.354zM34.986 28.199c2.046-.101 3.536-.763 3.763-2.686-1.919.231-2.947 1.349-3.763 2.686zm-1.613-3.759c1.367-.066 2.084-.782 2.151-2.149-1.371.065-2.086.783-2.151 2.149zm5.913-4.835c3.083-.118 4.874.505 5.714 1.537v4.003c-2.648 5.552-13.435 12.255-16.465 4.666-2.19-5.491 4.746-9.976 10.751-10.206zM14.562 74.397c1.485 1.094 4.78.407 5.374-1.072-2.09-.488-4.4-.79-5.374 1.072zm5.912-1.61c3.206-.736 3.3-4.585 3.225-8.596-1.068-.007-.686-1.467-2.151-1.075 1.554 4.178.497 5.873-1.074 9.671zm-6.987-4.298c1.259-2.502 4.717-2.809 5.375-5.909-2.996.42-6.373 3.289-5.375 5.909zm10.748-7.521c3.696 6.907-.593 17.397-10.748 15.579-7.481-6.717-.452-21.063 10.748-15.579z"/>
                </g>
            </g>
        </svg>
    );
}

export default Palette