import React from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"
// import Image from "gatsby-image"
import SEO from "../components/seo"
import TopMenu from "../components/topMenu"
import Footer from "../components/footer"
import RandomGallery from "../components/randomGallery"
import slugHandler from "../api/slugHandlerBrowser"
import Button from "../components/button"
import tapeImg from "../assets/img/tape.svg"
import Tube from "../components/view/tube"
import Sneaker from "../components/view/sneaker"
import Apple from "../components/view/apple"
import Sun from "../components/view/sun"
import Brushes from "../components/view/brushes"
import Baloon from "../components/view/baloon"
import Heart from "../components/view/heart"
import Drawing from "../components/view/drawing"
import PlainIndex from "../components/view/plain-index"
import Ship from "../components/view/ship"
import Butterfly from "../components/view/butterfly"
// import Camera from "../components/view/camera"
import Palette from "../components/view/palette"
import VLine from "../components/view/vline"
import Clock from "../components/view/clock"
import Lesson from "../components/lesson"
import Activity from "../components/activity"
import Modal from 'react-modal';
import { GrClose} from "react-icons/gr"
import BookForm from "../components/bookForm"

Modal.defaultStyles.overlay.zIndex = '20';
Modal.setAppElement('#___gatsby');

const IndexPageTemplate = ({ data, pageContext }) => {
  const { frontmatter: page } = data.page;
  const slug = data.page.fields.slug;
  const news = data.news.nodes;
  const newsTitle = data.newsTitle.frontmatter;
  const aboutTitle = data.aboutTitle.frontmatter;
  const galleryTitle = data.galleryTitle.frontmatter;
  const schedule = data.schedule.frontmatter;
  // const email = data.settings.childMdx.frontmatter.email;

  const weekdays = data.classes.childMdx.frontmatter.weekdays;
  // console.warn("page: ", pageContext.translation);

  const [bookFormOpen, setBookFormOpen] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState("");

  // function openBookForm() {
  //   setBookFormOpen(true);
  //   console.warn("Book Form Open");
  // }
  
  function closeBookForm(){
    setBookFormOpen(false);
    // console.warn("Book Form Close");
  }

  function lessonClick(lesson){
    const gr = pageContext.translation.schedule[lesson.day.title.toLowerCase()] + " " +
    lesson.time + " " +
    pageContext.translation.schedule[lesson.category] + " (" +
    lesson.language.toUpperCase()
    +")";
    // console.warn("Lesson click", gr);
    setSelectedGroup(gr);
    setBookFormOpen(true);
  }
  function bookClick(){
    // console.warn("Book click");
    setSelectedGroup("");
    setBookFormOpen(true);
  }


  return (
    <section className={"vp-lang-" + pageContext.language}>
      <SEO
        title="Home"
        description={page.description}
      />
    <div className="page">
    <TopMenu lang={page.language} languages={pageContext.languages} slug={slug} translation={pageContext.translation}></TopMenu>
    <div className="container-1200">
      <section className="vp-hero">
        <div className="vp-hero-columns columns aic">
          <div className="column-40">
              <div className="vp-hero-drawing__tube vp-hero-drawing--mobile color-accent-1"><Drawing><Tube /></Drawing></div>
              <div className="vp-hero-drawing__sneaker vp-hero-drawing--mobile color-accent-2"><Drawing delay="1000" direction="bottom-left"><Sneaker /></Drawing></div>
              <div className="vp-hero-drawing__brushes vp-hero-drawing--mobile color-accent-3"><Drawing delay="2000" direction="bottom-right"><Brushes /></Drawing></div>
              
            <div className="mb2">
              {
                page.heading.map((string, i) => <h2 key={i} className={"vp-hero-title color-accent-"+(i+1)}>{string.title}</h2>)
              }
            </div>
            <div className="vp-hero-subheading mb1">
            <ReactMarkdown source={page.subheading} linkTarget="_blank"/>
            </div>
            <Link to={slugHandler(pageContext.language, "/")+"#time"}>
              <Button>{pageContext.translation.buttons.book}</Button>
            </Link>
            
          </div>
          <div className="column-60">
            <div className="vp-hero-frame">
              <div className="vp-hero-drawing__tube color-accent-1"><Drawing><Tube /></Drawing></div>
              <div className="vp-hero-drawing__sneaker color-accent-2"><Drawing delay="1000" direction="bottom-left"><Sneaker /></Drawing></div>
              <div className="vp-hero-drawing__brushes color-accent-3"><Drawing delay="2000" direction="bottom-right"><Brushes /></Drawing></div>
              
              <video className="vp-hero-video"
              poster={page.heroPoster}
              autoPlay
              muted
              loop>
                {/* <source src={video} type="video/mp4"></source> */}
                <source src={page.heroVideo} type="video/mp4"></source>
              </video>
              
              {/* <iframe className="vp-hero-video" title="Intro video" src="https://www.youtube.com/embed/WTB264HzOII?autoplay=1&muted=1&playsinline=1" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
              <img src={tapeImg} className="vp-hero-frame__tape" alt="tape"></img>
              <img src={tapeImg} className="vp-hero-frame__tape vp-hero-frame__tape--2" alt="tape"></img>
            </div>
          </div>
        </div>
      </section>
      <section className="vp-hero section">
        <div className="vp-news-drawing__plain1 color-accent-1">
          <Drawing delay="1500"><PlainIndex /></Drawing>
        </div>
        <h1 className="color-accent-3">{newsTitle.title}</h1>
        <div className="vp-news-list columns mb1">
            {
              news.map((album)=>{
                const slug = album.slug.split('.')[0];
                const path = slugHandler(
                  pageContext.language,
                  slug
                )
                
                return (
                  <Link to={path} key={album.id} className="vp-news-list-item">
                    <div style={{"backgroundImage": `url(${album.frontmatter.image})`}} className="vp-news-list-item__bg mb1">
                    </div>
                    <div className="">
                      <div className="op05 fz18">{album.frontmatter.date}</div>
                      <div className="fwb mb05">{album.frontmatter.title}</div>
                      <Button color="3" size="small">{pageContext.translation.buttons.readMore}</Button>
                    </div>
                  </Link>
                )
              })
            }
        </div>
        <div className="tac">
          <Link to={slugHandler(pageContext.language, newsTitle.slug)}>
            <Button color="3">{pageContext.translation.buttons.showAll}</Button>
          </Link>
        </div>
      </section>
      <section className="vp-about section">
          
        <h1 className="color-accent-2 tac">
          <span className="vam mr20">
            {/* <Drawing><Camera></Camera></Drawing> */}
            <Drawing><Palette /></Drawing>
          </span>
          <span className="vp-about-title__text vam">
          {aboutTitle.title}
          </span>
          </h1>
        <div className="vp-section-header columns aic">
          <div className="column column-50 posr mr1">
            <img src={page.aboutImage} className="" alt="me"></img>
            <div className="vp-about-drawing__heart1 color-accent-2">
              <Drawing delay="1000"><Heart/></Drawing>
            </div>
            <div className="vp-about-drawing__heart2 color-accent-2">
              <Drawing delay="1500" direction="bottom-right" ><Heart/></Drawing>
            </div>
          </div>
          <div className="column column-50">
          <p>
          {aboutTitle.description}
          </p>
          <Link to={slugHandler(pageContext.language, aboutTitle.slug)}>
            <Button color="22">{pageContext.translation.buttons.readMore}</Button>
          </Link>
          </div>
        </div>
      </section>

      <section className="vp-what section" id="classes">
        <h1 className="color-accent-1 tac">
          <span className="vam mr20">
          <Drawing direction="top-left"><Sun></Sun></Drawing>
          </span>
          <span className="vp-what-drawing__header vam">{page.whatwedotitle}</span>
        </h1>
        <Activity {...page.whatwedo[0]} color="1" btnText={pageContext.translation.buttons.readMore}></Activity>
        <div className="vp-what-drawing__ship color-accent-3 tac mb2">
          <Drawing><Ship></Ship></Drawing>
        </div>
        <Activity {...page.whatwedo[1]} color="3" even={true} btnText={pageContext.translation.buttons.readMore}></Activity>
        <div className="vp-what-drawing__butterfly color-accent-2 tac mb2">
          <Drawing direction="top-left"><Butterfly></Butterfly></Drawing>
        </div>
        <Activity {...page.whatwedo[2]} color="22" btnText={pageContext.translation.buttons.readMore}></Activity>
      </section>

    </div>
    </div>

      <section className="vp-index-gal section">
        <div className="vp-section-header vp-index-gal__header container-1200 columns aic mb2">
          <div className="column column-50 posr mr2">
            <h1 className="color-accent-3 mb0 tac"> 
              <span className="vp-index-gal-drawing__camera color-accent-3 vam">
                
                <Drawing direction="top-left"><Brushes/></Drawing>
              </span>
              <span className="vam">{galleryTitle.title}</span> 
              <span className="vp-index-gal__vline color-accent-3 vam">
                <VLine></VLine>
              </span>
            </h1>
          </div>
          <div className="column column-50">
          <p>
          {galleryTitle.description}</p>
          </div>
        </div>

        <RandomGallery />
            
        <div className="buttons tac">
          <Link to={slugHandler(pageContext.language, galleryTitle.slug)}>
            <Button color="3">{pageContext.translation.buttons.showAll}</Button>
          </Link>
        </div>

      </section>

    <div className="page">
    <div className="container-1200">

      <section className="vp-time-table section" id="time">
        <div className="vp-section-header vp-time-table__header columns aic mb2">
          <div className="column column-50 posr mr2">
            <h1 className="color-accent-2 mb0 tac"> 
              <span className="vp-index-gal-drawing__camera vam" style={{maxWidth: "137px"}}>
                <Drawing direction="bottom-left"><Clock></Clock></Drawing>
              </span>
              <span className="vam">{schedule.title}</span> 
              <span className="vp-index-gal__vline vam">
                <VLine></VLine>
              </span>
            </h1>
          </div>
          <div className="column column-50">
          <p>
          {schedule.description}
          </p>
          <p>
            {/* <a href={"mailto:"+email+'?subject=Varaa paikkani'}> */}
              <Button color="1" onClick={bookClick}>{pageContext.translation.buttons.book}</Button>
            {/* </a> */}
          </p>
          </div>
        </div>
        <div className="vp-time-table__body columns posr">
              <span className="vp-time-table-drawing__apple color-accent-1">
                <Drawing><Apple></Apple></Drawing>
              </span>
            {
              weekdays.map((day, i) => (
                <div key={i} className="vp-time-table__day mb2">
                  <h3>{pageContext.translation.schedule[day.title.toLowerCase()]}</h3>
                  <ul className="vp-time-table-lessons">
                    {
                     day.lessons.map((lesson, l) => <Lesson key={l} translation={pageContext.translation} onClick={() => lessonClick({ day, ...lesson })} {...lesson}></Lesson>)
                    }
                  </ul>
                </div>
              ))
            }
        </div>
        <div className="tac">
            {/* <div className="vp-time-table-drawing__sun color-accent-2">
              <Drawing direction="top-left"><Sun></Sun></Drawing>
            </div> */}

          <div className="color-accent-2 dib">
          <Drawing><Baloon></Baloon></Drawing>
          </div>
        </div>
      </section>
      <form name="bookForm" id="bookForm" method="POST" className="form dn" data-netlify="true" data-netlify-honeypot="bot-field" netlify>
        <label><span >name</span><input type="text" name="name" className="form-input"/></label> 
        <label><span >email</span><input type="email" name="email" className="form-input"/></label> 
        <label><span >tel</span><input type="text" name="tel" className="form-input"/></label> 
        <label><span >group</span><input type="text" name="group" className="form-input"/></label> 
        <label><span >text</span><input type="text" name="text" className="form-input"/></label> 
      </form>
      <Modal
        isOpen={bookFormOpen}
        onRequestClose={closeBookForm}
        contentLabel={pageContext.translation.buttons.book}
        className={"modal vp-lang-" + pageContext.language}
        // overlayClassName="modal-overlay"
      >
        <button onClick={closeBookForm} className="modal-close">
          <GrClose></GrClose>
        </button>
          <BookForm group={selectedGroup} weekdays={weekdays} onRequestClose={closeBookForm} translation={pageContext.translation} />
      </Modal>

      <Footer lang={pageContext.language} languages={pageContext.languages} translation={pageContext.translation}></Footer>

    </div>
    </div>
    </section>
  )
}

export const indexQuery = graphql`
  query IndexById($id: String!, $language: String!, $langSlugRegEx: String!) {
    page: mdx(
      id: { eq: $id },
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/index" }
        language: { eq: $language }
      }
    ) {
      fields {
        slug
      }
      frontmatter {
        language
        title
        description
        heading {
          title
        }
        subheading
        heroVideo
        heroPoster
        aboutImage
        whatwedotitle
        whatwedo {
          image
          link
          subtitle
          title
        }
      }
    }
    newsTitle: mdx(
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/news" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        slug
        title
        description
      }
    }
    aboutTitle: mdx(
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/about" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        slug
        title
        description
      }
    }
    news: allMdx(filter: {frontmatter: {templateKey: {eq: "album"}, tags: {regex: "/news/"}}, slug: {regex: $langSlugRegEx }}, sort: {fields: [frontmatter___date], order: DESC}, limit: 3) {
      nodes {
        slug
        id
        frontmatter {
          date(formatString: "DD MMMM YYYY")
          title
          image
        }
      }
    }
    galleryTitle: mdx(
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/albums" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        slug
        title
        description
      }
    }
    classes: file(sourceInstanceName: {eq: "settings"}, name: {eq: "classes"}) {
      childMdx {
        frontmatter {
          weekdays {
            title
            lessons {
              category
              language
              time
            }
          }
        }
      }
    }
    schedule: mdx(
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/schedule" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        slug
        title
        description
      }
    }
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          email
        }
      }
    }
  }
`
export default IndexPageTemplate
