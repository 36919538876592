import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import StripeGallery from "./stripeGallery"
import { getRandomInt } from "../utils/utils"
// import ReactMarkdown from "react-markdown"

const RandomGallery = (props) => {
  const data = useStaticQuery(graphql`
  query randomGallery {
    albums: allMdx(filter: {frontmatter: {templateKey: {eq: "album"}, tags: {regex: "/gallery/"}}}) {
      nodes {
        id
        slug
        frontmatter {
          date(formatString: "DD MMMM YYYY")
          description
          title
          pictures {
            image
            title
          }
        }
      }
      totalCount
    }
    cloudinaryImages: allCloudinaryItem {
      nodes {
        id
        public_id
        thumb
        imgUrl
        width
        height
        orientation
      }
    }
  }
`);
    const fileNameRegExp = /\/([^/]+)\.\w{1,4}$/;
    const albumsPics = data.albums.nodes.reduce((sum, al) => {
      const pics = al.frontmatter.pictures || [];
      return [...sum, ...pics]
    }, []);
    const cloudinaryImages = data.cloudinaryImages.nodes;

    let randomPics = []
    for (let i = 0; i < 20; i++) {
      let index = getRandomInt(0, albumsPics.length - 1);
      if (randomPics.includes(albumsPics[index])) {
        i--;
        continue;
      }
      // if (this.checkIds(gallery, this.total.blurbs[index])) {
      //     i--;
      //     continue;
      // }
      randomPics.push(albumsPics[index]);
  }
    // console.warn(randomPics);

    let pictures = randomPics.map((pic, index) => {
      const res = fileNameRegExp.exec(pic.image);
      // console.warn(res);
      if (!res || !res[1]) return undefined;
      const picData = cloudinaryImages.filter(pic => pic.public_id.includes(res[1]))[0];
      return {
        index: index,
        type: picData.type || "image",
        id: picData.id,
        src: picData.imgUrl,
        thumbnail: picData.thumb,
        w: picData.width,
        h: picData.height,
        title: pic.title || "",
        description: picData.description || "",
      };
    });

    const getThumbnailContent = (item) => {
      let deg = getRandomInt(-5, 5);
      return (
        <div className="vp-album-stripe-item__in" style={{transform: `rotate(${deg}deg)`}}>
          <img src={item.thumbnail} alt={item.title} loading="lazy" className="vp-album-item__pic mb1"/>
          <h5 className="mb0">{item.title}</h5>
        </div>
      );
    }

    return (
       <div className="">
              {/* <div className="vp-album-desc">
                <ReactMarkdown source={album.frontmatter.description} linkTarget="_blank"/>
              </div> */}
          <StripeGallery items={pictures} options={{}} thumbnailContent={getThumbnailContent}/>
        </div>
    )
  }

export default RandomGallery


