import React from "react"
import Button from "./button"
import { encodeObject } from "../utils/utils"

function BookForm(props){

    const [bookForm, setBookForm] = React.useState({
        name: "",
        email: "",
        tel: "",
        group: props.group || props.translation.scheduleForm.helpText,
        text: "",
    });

    const [bookSuccess, setBookSuccess] = React.useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        // console.warn("BookForm", bookForm);

        fetch(props.action || "/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encodeObject({ "form-name": "bookForm", ...bookForm })
        })
          .then(() => {
            setBookSuccess(true);
            if (props.onAfterSubmit) props.onAfterSubmit();
          })
          .catch(error => alert(error));
  
      };

    const handleChange = e => setBookForm({ ...bookForm, [e.target.name]: e.target.value });

    // console.warn(props.weekdays);
      const groups = props.weekdays.reduce((acc, day) => {
          const labels = day.lessons.map(lesson => props.translation.schedule[day.title.toLowerCase()] + " " +
          lesson.time + " " +
          props.translation.schedule[lesson.category] + " (" +
          lesson.language.toUpperCase()
          +")");
          return [...acc, ...labels]
        }, []);
      
    //   props.weekdays.map(day => day.lessons.map(lesson => props.translation.schedule[day.title.toLowerCase()] + " " +
    //   lesson.time + " " +
    //   props.translation.schedule[lesson.category]));
    // console.warn(groups);

    
    return (
        <div className="vp-book">
            <h3 className="color-accent-2">{props.translation.scheduleForm.header}</h3>
            {
            !bookSuccess && <form name="bookForm" id="bookForm" method="POST" className="form" onSubmit={handleSubmit} data-netlify="true" data-netlify-honeypot="bot-field" netlify>
                <input type="hidden" name="form-name" value="book" />
                <p>
                <label><span className="form-label__text">{props.translation.scheduleForm.name}</span><input type="text" name="name" className="form-input" onBlur={handleChange} required /></label>   
                </p>
                <p>
                <label><span className="form-label__text">{props.translation.scheduleForm.email}</span><input type="email" name="email" className="form-input" onBlur={handleChange} required /></label>
                </p>
                <p>
                <label><span className="form-label__text">{props.translation.scheduleForm.tel}</span><input type="tel" name="tel" className="form-input" onBlur={handleChange} /></label>
                </p>
                <p>
                <label>
                    <span className="form-label__text">{props.translation.scheduleForm.group}</span> 
                    <select name="group" className="form-input" onBlur={handleChange} value={bookForm.group}>
                        <option value={props.translation.scheduleForm.helpText}>{props.translation.scheduleForm.helpText}</option>
                    {
                        groups.map((group, g) => <option key={g} value={group}>{group}</option>)
                    }
                    </select>
                </label>
                </p>
                <p>
                <label><span className="form-label__text">{props.translation.scheduleForm.text} </span><textarea name="text" className="form-input form-input--text" onBlur={handleChange}></textarea></label>
                </p>
                <p className="tac">
                <Button color="1">{props.translation.buttons.book}</Button>
                </p>
            </form>
            }
            {
                bookSuccess && <div className="vp-book-success">
                    <div className="mb1">{props.translation.scheduleForm.successText}</div>
                    <div className="tac">
                    <Button color="1" onClick={props.onRequestClose}>{props.translation.buttons.ok}</Button>

                    </div>
                </div>
            }

        </div>
    )
}

export default BookForm